import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { InsumoService } from "src/app/_services/insumos.service";
import { InsumosFieldsService } from "src/app/_services/insumosFields.service";
import { UploadService } from "src/app/_services/upload.service";
import { environment } from "src/environments/environment";
import * as uuid from "uuid";
import { isHoliday } from "../../_helpers/saoPauloHolidays";
import { Insumo } from "../../_models/insumos";
import { CompetenceDatePipe } from "../../_pipes/competence-date.pipe";
import { DeliveryDatePipe } from "../../_pipes/deliveryDate.pipe";

@Component({
  selector: "app-insumo-visualizer",
  templateUrl: "./insumo-visualizer.component.html",
  styleUrls: ["./insumo-visualizer.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  providers: [CompetenceDatePipe, DeliveryDatePipe],
})
export class InsumoVisualizerComponent implements OnInit {
  insumoId: string | number;

  private uploadSubscription: Subscription | undefined;
  isLoadingAnexo: boolean = false;

  submitted = false;
  fields: any[] = [];
  isChecked = true;
  filesImport = [];

  insumoUpdate: FormGroup;
  anexoInsumo: FormGroup;
  formUpdate: FormGroup;
  formDeadLine: FormGroup;
  formDeadLineSon: FormGroup;
  formGroup: FormGroup;
  formValidators: FormGroup;
  formChangeVersion: FormGroup;
  formAddNumeroSd: FormGroup;

  areaOptions: string[] = [];

  tipoDeInsumoOptions: string[] = [];
  actualInsumoId: any;
  insumoVersionList: string[] = [];
  holydays: string[] = [];
  tempInsumoId: any;
  start = false;
  user: User;
  userToken: string;
  insumo: Insumo = {} as Insumo;
  segmentoOptions: string[] = [];
  publicoOptions: string[] = [];

  modalUpdateRef: MatDialogRef<any>;

  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  messageErrorUpload = "";
  errorMsg: string;
  filteredUsers1: any;
  filteredUsers2: any;
  filteredUsersFilter: any;
  isLoading = false;
  actualInsumoLength: number = 0;

  files = [];
  attachments: any[] = [];
  anexoDownload: string = environment.apiUrl + "/download/anexo-insumo/";
  hasInsumoJustify = new FormControl();
  uploadLoading: boolean = false;
  insumoCompetenceDate = moment();

  frequency = new FormControl();
  frequencyOptions: string[] = [];

  formData: MatTableDataSource<any>;
  dataSourceForDownload: MatTableDataSource<any>;
  dataSource = new MatTableDataSource<any>();
  dataSourceLength: number = 0;
  isLoadingResults: boolean = true;
  isDownloadProcess: boolean = false;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  validatorCtrl = new FormControl();
  filteredValidators: Observable<string[]>;
  validators: any[] = [];
  allValidators: any;

  isInsumoSon: boolean = false;
  deadlineValue: Date = moment().add(1, "days").toDate();

  @ViewChild("validatorInput") validatorInput: ElementRef<HTMLInputElement>;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild("fileUploadInsumo", { static: false })
  fileUploadInsumo: ElementRef;

  @ViewChild("closeModalAlterarResponsavel")
  closeModalAlterarResponsavel: ElementRef;
  @ViewChild("closeModalAlterarDeadLine")
  closeModalAlterarDeadLine: ElementRef;
  @ViewChild("closeModalAlterarDeadLineSon")
  closeModalAlterarDeadLineSon: ElementRef;

  @ViewChild("closeModalHasInsumos") closeModalHasInsumos: ElementRef;
  @ViewChild("closeFrequencyModal") closeFrequencyModal: ElementRef;
  @ViewChild("openModal") openModal: ElementRef;
  @ViewChild("closeModalExcluir") closeModalExcluir: ElementRef;
  @ViewChild("confirmUploadModal") confirmUploadModal: ElementRef;
  @ViewChild("closeModalEncerrar") closeModalEncerrar: ElementRef;
  @ViewChild("closeConfirmDoc") closeConfirmDoc: ElementRef;
  @ViewChild("encerradosNada") encerradosNada: ElementRef;
  @ViewChild("closeModalVersion") closeModalVersion: ElementRef;
  @ViewChild("exportModal") exportModal: ElementRef;
  @ViewChild("closeModalUpdateInsumo") closeModalUpdateInsumo: ElementRef;
  @ViewChild("errorModal") errorModal: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private insumosService: InsumoService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private insumoService: InsumoService,
    private http: HttpClient,
    private uploadService: UploadService,
    private insumoFieldService: InsumosFieldsService
  ) {
    this.user = this.accountService.userValue.user;
    this.userToken = this.accountService.userValue.token;
  }

  ngOnInit(): void {
    this.formChangeVersion = this.formBuilder.group({
      version: ["", Validators.required],
      justificativa: ["", Validators.required],
    });

    this.validatorCtrl.setValidators([Validators.required]);
    this.hasInsumoJustify.setValidators([Validators.required]);
    this.frequency.setValidators([Validators.required]);
    this.getInsumoFields();
    this.getHolidays();

    this.formAddNumeroSd = this.formBuilder.group({
      numeroSd: ["", Validators.required],
    });

    this.anexoInsumo = this.formBuilder.group({
      justify: [
        "",
        [
          Validators.minLength(3),
          Validators.maxLength(300),
          Validators.required,
        ],
      ],
    });
    this.insumoUpdate = this.formBuilder.group({
      name: [],
      segment: [],
      publico: [],
      area: [],
      frequency: [],
      competence: [],
      deadline: [],
      observations: [],
      insumoType: [],
      docTypeCheckboxGroup: new FormGroup({
        docTypeCheck1: new FormControl(""),
        docTypeCheck2: new FormControl(""),
        docTypeCheck3: new FormControl(""),
        docTypeCheck4: new FormControl(""),
        docTypeCheck5: new FormControl(""),
      }),
      validators: [],
      insumoAmount: [],
    });

    this.formUpdate = this.formBuilder.group({
      responsibleUpload: ["", [Validators.required]],
      analysisUpload: ["", [Validators.required]],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.formDeadLine = this.formBuilder.group({
      deadline: [moment(), Validators.required],
      utilDay: [false],
      dayOnMonth: [true],
      lastDayUtil: [false],
      limitDate: [""],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.formDeadLineSon = this.formBuilder.group({
      deadline: [moment(), Validators.required],
      utilDay: [false],
      dayOnMonth: [true],
      lastDayUtil: [false],
      limitDate: [""],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.searchUserCtrl1.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers1 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers1 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers1 = data;
        }
      });

    this.searchUserCtrl2.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers2 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers2 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers2 = data;
        }
      });

    this.validatorCtrl.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.allValidators = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.allValidators = [];
        } else {
          this.errorMsg = "";
          this.allValidators = data;
        }
      });

    this.route.params.subscribe((params) => (this.insumoId = params["id"]));
    this.getInsumoById(this.insumoId);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our validator
    if (value) {
      this.validators.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.validatorCtrl.setValue(null);
  }

  remove(validator: string): void {
    const index = this.validators.indexOf(validator);

    if (index >= 0) {
      this.validators.splice(index, 1);
    }
  }

  onChangeLimitDate(index: number) {
    switch (index) {
      case 1:
        this.formDeadLine.get("dayOnMonth").setValue(true);
        this.formDeadLine.get("utilDay").setValue(false);
        break;
      case 2:
        this.formDeadLine.get("dayOnMonth").setValue(false);
        this.formDeadLine.get("utilDay").setValue(true);
        break;
      case 3:
        this.formDeadLine.get("limitDate").reset();
        if (this.formDeadLine.get("lastDayUtil").value == true) {
          this.formDeadLine.get("limitDate").disable();
        } else {
          this.formDeadLine.get("limitDate").enable();
        }
        break;
    }
  }

  onChangeLimitDateSon(index: number) {
    switch (index) {
      case 1:
        this.formDeadLineSon.get("dayOnMonth").setValue(true);
        this.formDeadLineSon.get("utilDay").setValue(false);
        break;
      case 2:
        this.formDeadLineSon.get("dayOnMonth").setValue(false);
        this.formDeadLineSon.get("utilDay").setValue(true);
        break;
      case 3:
        this.formDeadLineSon.get("limitDate").reset();
        if (this.formDeadLineSon.get("lastDayUtil").value == true) {
          this.formDeadLineSon.get("limitDate").disable();
        } else {
          this.formDeadLineSon.get("limitDate").enable();
        }
        break;
    }
  }

  setCompetenceFormValue($event: EventEmitterValue) {
    this.insumoUpdate.get("competence").setValue($event.competence);
  }

  populateInsumoUpdateModal() {
    this.insumoUpdate.get("name").setValue(this.insumo.name);
    this.insumoUpdate.get("segment").setValue(this.insumo.segment.trim());
    this.insumoUpdate.get("publico").setValue(this.insumo.publico.trim());
    this.insumoUpdate.get("area").setValue(this.insumo.area);
    this.insumoUpdate.get("frequency").setValue(this.insumo.frequency);
    this.insumoUpdate.get("observations").setValue(this.insumo.observations);
    this.insumoUpdate.get("insumoType").setValue(this.insumo.type);
    this.insumoUpdate.get("insumoAmount").setValue(this.insumo.insumoAmount);
    this.insumoUpdate
      .get("competence")
      .setValue(this.insumoCompetenceDate.toISOString());

    this.insumo.acceptedDocs.split(",").forEach((doc) => {
      if (doc === "todos") {
        this.insumoUpdate
          .get("docTypeCheckboxGroup")
          .get(`docTypeCheck5`)
          .setValue(true);
        this.insumoUpdate
          .get("docTypeCheckboxGroup")
          .get(`docTypeCheck4`)
          .setValue(true);
        this.insumoUpdate
          .get("docTypeCheckboxGroup")
          .get(`docTypeCheck3`)
          .setValue(true);
        this.insumoUpdate
          .get("docTypeCheckboxGroup")
          .get(`docTypeCheck2`)
          .setValue(true);
        this.insumoUpdate
          .get("docTypeCheckboxGroup")
          .get(`docTypeCheck1`)
          .setValue(true);
      } else {
        if (doc === ".xls") {
          this.insumoUpdate
            .get("docTypeCheckboxGroup")
            .get(`docTypeCheck1`)
            .setValue(true);
        } else if (doc === ".xlsx") {
          this.insumoUpdate
            .get("docTypeCheckboxGroup")
            .get(`docTypeCheck2`)
            .setValue(true);
        } else if (doc === ".csv") {
          this.insumoUpdate
            .get("docTypeCheckboxGroup")
            .get(`docTypeCheck3`)
            .setValue(true);
        } else if (doc === ".pdf") {
          this.insumoUpdate
            .get("docTypeCheckboxGroup")
            .get(`docTypeCheck4`)
            .setValue(true);
        }
      }
    });
  }

  getInsumosFieldsPeriodicidade() {
    this.insumoFieldService
      .getByType("PERIODICIDADE")
      .subscribe((data: any[]) => {
        const localeFrequencyOptions: string[] = [];

        data.forEach((freq) =>
          localeFrequencyOptions.push(freq.fieldDescription)
        );

        this.frequencyOptions = [
          ...new Set([...localeFrequencyOptions, ...this.frequencyOptions]),
        ];
      });
  }

  updateFrequency() {
    const data = {
      frequency: this.frequency.value,
      ticketId: this.insumo.id,
      userId: this.user.id,
    };

    this.insumosService.updateFrequency(data).subscribe((data) => {
      this.frequency.reset();
      this.closeFrequencyModal.nativeElement.click();
      this.getInsumoById(this.insumo.id);
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.validators.push(event.option.value);
    this.validatorInput.nativeElement.value = "";
    this.validatorCtrl.setValue(null);
  }

  uploadAnexoInsumo(insumo: any) {
    this.insumo = insumo;
    this.actualInsumoLength = insumo.attachments.length;
  }

  selectInsumoVersion(insumo: any) {
    this.insumo = insumo;
    this.insumo["insumoAnexos"].forEach((element) => {
      this.insumoVersionList.push("v" + element.version);
    });

    return;
  }

  insumoAtraso(element) {
    const date = new Date().getTime();
    const deadline = new Date(element.deadline).getTime();

    if (date > deadline) {
      return true;
    }

    return false;
  }

  onReset() {
    this.getInsumoById(this.insumoId);
  }

  editPermission(responsibleUser?: string) {
    if (
      (responsibleUser === this.user.email &&
        this.user.userType !== "OPERACAO_VIVO") ||
      this.user.userType == "EQUIPE_INTERNA" ||
      this.user.userType == "OPERACAO_BPO"
    ) {
      return false;
    }

    return true;
  }

  onChangeInsumoDeadline() {
    if (
      this.user.userType === "OPERACAO_BPO" &&
      this.user.profile === "ROLE_ADMIN" &&
      this.insumo.competenceStatus === "VIGENTE"
    ) {
      return false;
    }

    return true;
  }

  isOperador() {
    if (this.user.userType === "OPERACAO_VIVO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_JURIDICO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_AUDITORIA") {
      return false;
    } else {
      return true;
    }
  }

  onChangeInsumoSon() {
    this.isInsumoSon = !this.isInsumoSon;
  }

  onSubmit(): void {
    if (
      this.insumo.competenceStatus === "ENCERRADO" &&
      !this.insumo.allowNewUpload
    ) {
      return;
    }
    this.submitted = true;
    let updateAnexosInsumoDto: any = {};
    this.uploadLoading = true;

    if (this.actualInsumoLength > 0) {
      if (this.anexoInsumo.invalid) {
        this.uploadLoading = false;
        return;
      }

      updateAnexosInsumoDto = {
        userId: this.user.id,
        anexos: this.attachments.map((att) => ({
          ...att,
          userUpload: null,
        })),
        justificativa: this.anexoInsumo.get("justify").value,
        insumoId: this.insumo.id,
      };
      this.insumosService.updateAnexos(updateAnexosInsumoDto).subscribe(() => {
        this.anexoInsumo.reset();
        this.attachments = [];
        this.files = [];
        this.closeConfirmDoc.nativeElement.click();
        this.uploadLoading = false;
        this.submitted = false;
        this.getInsumoById(this.insumo.id);
      });
    } else {
      updateAnexosInsumoDto = {
        userId: this.user.id,
        anexos: this.attachments.map((att) => ({
          ...att,
          userUpload: null,
        })),
        justificativa: "",
        insumoId: this.insumo.id,
      };

      this.insumosService.updateAnexos(updateAnexosInsumoDto).subscribe(() => {
        this.attachments = [];
        this.files = [];
        this.closeConfirmDoc.nativeElement.click();
        this.uploadLoading = false;
        this.submitted = false;
        this.getInsumoById(this.insumo.id);
      });
    }
  }

  get formUp(): { [key: string]: AbstractControl } {
    return this.formUpdate.controls;
  }

  desativarInsumo(insumoId: number) {
    const insumoDto = {
      insumoId: +insumoId,
      userId: this.user.id,
    };
    this.insumosService.inativarInsumo(insumoDto).subscribe(() => {
      this.getInsumoById(this.insumo.id);
    });
  }

  utilDaysFormat(insumo) {
    if (insumo.utilDays === "-1") {
      return "Último dia útil";
    } else if (/^d\d+$/.test(insumo.utilDays)) {
      const dayOfMonth = insumo.utilDays.slice(1);
      return `Todo dia ${dayOfMonth} do mês`;
    } else if (/^\d+d$/.test(insumo.utilDays)) {
      const dayOfMonth = insumo.utilDays.slice(0, -1);
      return `Todo dia ${dayOfMonth} do mês`;
    } else {
      return `Todo ${insumo.utilDays}° dia útil`;
    }
  }

  utilDaysFormatSon(insumo) {
    if (insumo.actualUtilDays === "-1") {
      return "Último dia útil";
    } else if (/^d\d+$/.test(insumo.actualUtilDays)) {
      const dayOfMonth = insumo.actualUtilDays.slice(1);
      return `Todo dia ${dayOfMonth} do mês`;
    } else if (/^\d+d$/.test(insumo.actualUtilDays)) {
      const dayOfMonth = insumo.actualUtilDays.slice(0, -1);
      return `Todo dia ${dayOfMonth} do mês`;
    } else {
      return `Todo ${insumo.actualUtilDays}° dia útil`;
    }
  }

  disableUploadButton(element: Insumo) {
    if (element.status === "INATIVO" || element.status === "ENCERRADO") {
      return true;
    }
    if (!element.hasInsumo) {
      return true;
    }
    if (element.allowNewUpload) {
      return false;
    }
    if (element.allowToUpload) {
      return false;
    } else {
      return true;
    }
  }
  validatorCheck(insumo) {
    const hasValidator = insumo.validators.some(
      (validator) => validator.email === this.user.email
    );
    return hasValidator;
  }

  getInsumoById(id) {
    this.isLoadingResults = true;
    this.insumosService.getInsumoById(id).subscribe((data: Insumo) => {
      this.insumo = data;
      this.actualInsumoLength = data.attachments.length;
      this.isLoadingResults = false;
      this.setDeadlineForInsumoChange();
      this.setDeadlineForInsumoSonChange();
      this.getInsumosFieldsPeriodicidade();
      this.setDefaultInsumoCompetenceDate();
    });
  }

  setDeadlineForInsumoChange() {
    const persistedDeadLine = moment();

    const insumoDeadlineSplit = this.insumo.deadline.split("/");

    persistedDeadLine.date(Number(insumoDeadlineSplit[0]));
    persistedDeadLine.month(Number(insumoDeadlineSplit[1]) - 1);
    persistedDeadLine.year(Number(insumoDeadlineSplit[2]));

    this.formDeadLine.get("deadline").setValue(persistedDeadLine.toDate());
  }

  setDeadlineForInsumoSonChange() {
    const persistedDeadLine = moment();

    const insumoDeadlineSplit = this.insumo.actualDeadline.split("/");

    persistedDeadLine.date(Number(insumoDeadlineSplit[0]));
    persistedDeadLine.month(Number(insumoDeadlineSplit[1]) - 1);
    persistedDeadLine.year(Number(insumoDeadlineSplit[2]));

    this.formDeadLineSon.get("deadline").setValue(persistedDeadLine.toDate());
  }

  setDefaultInsumoCompetenceDate() {
    const month = Number(this.insumo.competence.split("/")[0]);
    const year = Number(this.insumo.competence.split("/")[1]);

    this.insumoCompetenceDate.date(1);
    this.insumoCompetenceDate.month(month - 1);
    this.insumoCompetenceDate.year(year);
    this.insumoCompetenceDate.hour(0);
    this.insumoCompetenceDate.minute(0);
    this.insumoCompetenceDate.second(0);
    this.insumoCompetenceDate.millisecond(0);
  }

  openHasInsumoModal(event) {
    if (!event.checked) {
      this.openModal.nativeElement.click();
    } else {
      this.changeHasInsumo();
    }
  }

  changeHasInsumo() {
    const hasInsumoDto = {
      value: this.insumo.hasInsumo,
      justify: this.hasInsumoJustify.value,
      insumoId: this.insumoId,
      userId: this.user.id,
    };

    this.insumosService.updateHasInsumo(hasInsumoDto).subscribe((res) => {
      this.getInsumoById(this.insumoId);
      this.closeModalHasInsumos.nativeElement.click();
      this.hasInsumoJustify.reset();
    });
  }

  changeAllowNewUpload() {
    const allowNewUploadDto = {
      value: this.insumo.allowNewUpload,
      insumoId: this.insumoId,
      userId: this.user.id,
    };

    this.insumosService
      .updateAllowNewUpload(allowNewUploadDto)
      .subscribe((res) => {
        this.getInsumoById(this.insumoId);
      });
  }

  excluirInsumo() {
    this.insumosService.deleteInsumo(this.tempInsumoId).subscribe((data) => {
      this.getInsumoById(this.insumo.id);
      this.tempInsumoId = null;
      this.closeModalExcluir.nativeElement.click();
    });
  }

  addNumeroSd() {
    const formAddNumeroSd = this.formAddNumeroSd.value;

    const insumoAddNumeroSdDto = {
      insumoId: this.actualInsumoId,
      userId: this.user.id,
      numeroSd: formAddNumeroSd.numeroSd,
    };

    this.insumosService
      .insumoAddNumeroSd(insumoAddNumeroSdDto)
      .subscribe(() => {
        location.reload();
      });
  }

  validationDeadLine(): boolean {
    let deadline = this.formDeadLine.get("deadline").value;
    deadline = this.formatData(deadline);

    let isInvalid = false;

    this.holydays.forEach((element) => {
      if (element == deadline) {
        this.formDeadLine.get("deadline")?.setErrors({ invalid: "holiday" });
        isInvalid = true;
      }
    });

    if (new Date(deadline).getDay() == 5 || new Date(deadline).getDay() == 6) {
      this.formDeadLine.get("deadline")?.setErrors({ invalid: "notWorkDay" });
      isInvalid = true;
    }

    const day = deadline.split("-")[2];
    const month = deadline.split("-")[1];

    if (isHoliday(day, month)) {
      this.formDeadLine.get("deadline")?.setErrors({ invalid: "holiday" });
      isInvalid = true;
    }

    return !isInvalid;
  }

  validationDeadLineSon(): boolean {
    let deadline = this.formDeadLineSon.get("deadline").value;

    if (moment(deadline).isBefore(moment())) {
      this.formDeadLineSon.get("deadline")?.setErrors({ invalid: "invalid" });
    }

    deadline = this.formatData(deadline);
    let isInvalid = false;

    this.holydays.forEach((element) => {
      if (element == deadline) {
        this.formDeadLineSon.get("deadline")?.setErrors({ invalid: "holiday" });
        isInvalid = true;
      }
    });

    if (new Date(deadline).getDay() == 5 || new Date(deadline).getDay() == 6) {
      this.formDeadLineSon
        .get("deadline")
        ?.setErrors({ invalid: "notWorkDay" });
      isInvalid = true;
    }

    const day = deadline.split("-")[2];
    const month = deadline.split("-")[1];

    if (isHoliday(day, month)) {
      this.formDeadLineSon.get("deadline")?.setErrors({ invalid: "holiday" });
      isInvalid = true;
    }

    return !isInvalid;
  }

  formatData(date: any) {
    let timeStamp_date = new Date(date);
    let year = timeStamp_date.getFullYear();
    let month = formatZero(timeStamp_date.getMonth() + 1);
    let day = formatZero(timeStamp_date.getDate());

    function formatZero(numero) {
      return numero <= 9 ? "0" + numero : numero;
    }
    return `${year}-${month}-${day}`;
  }

  encerrarInsumo() {
    const closeInsumoDto = {
      insumoId: this.tempInsumoId,
      userId: this.user.id,
    };
    this.insumosService.closeInsumo(closeInsumoDto).subscribe(() => {
      this.getInsumoById(this.insumo.id);
      this.tempInsumoId = null;
      this.closeModalExcluir.nativeElement.click();
    });
  }

  getTooltipForInsumo(insumo) {
    if (insumo.insumoOrigin === 0) {
      insumo.insumoOrigin = insumo.id;
    }
    return `ID DO INSUMO ORIGEM: ${insumo.insumoRaiz}\nID DA ÚLTIMA COMPETÊNCIA: ${insumo.insumoOrigin}`;
  }

  onSubmitUpdate(): void {
    this.submitted = true;

    if (this.formUpdate.invalid) {
      return;
    }

    let tempAlterarResponsavel: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      responsibleUpload: this.formUpdate.get("responsibleUpload").value,
      analysisUpload: this.formUpdate.get("analysisUpload").value,
      justify: this.formUpdate.get("justify").value,
    };

    this.submitted = false;
    this.updatedInsumoResponsavel(tempAlterarResponsavel);
  }

  onSubmitDeadLine(): void {
    this.submitted = true;

    if (!this.validationDeadLine()) {
      this.showModalDeadlineError();
      return;
    }

    if (this.formDeadLine.invalid) {
      return;
    }

    const deadlineObj = this.validadeDatesForUtilDays();

    const tempAlterarDeadLine: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      deadline: deadlineObj.deadline,
      utilDays: deadlineObj.utilDays,
      justify: this.formDeadLine.get("justify").value,
    };

    this.insumosService.updateDeadLine(tempAlterarDeadLine).subscribe(() => {
      this.closeModalAlterarDeadLine.nativeElement.click();
      this.formDeadLine.reset();
      this.submitted = false;
      this.actualInsumoId = null;
      this.getInsumoById(this.insumo.id);
    });
  }

  showModalDeadlineError() {
    const modalElement = this.errorModal.nativeElement;
    ($(modalElement) as any).modal("show");
  }

  onSubmitDeadLineSon(): void {
    this.submitted = true;

    this.validationDeadLineSon();

    if (!this.validationDeadLineSon()) {
      this.showModalDeadlineError();
      return;
    }

    if (this.formDeadLineSon.invalid) {
      return;
    }

    const deadlineObj = this.validadeDatesForUtilDaysForSon();

    const tempAlterarDeadLine: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      deadline: deadlineObj.deadline,
      utilDays: deadlineObj.utilDays,
      justify: this.formDeadLineSon.get("justify").value,
    };

    this.insumosService
      .updateDeadLineForActual(tempAlterarDeadLine)
      .subscribe(() => {
        this.closeModalAlterarDeadLine.nativeElement.click();
        this.formDeadLineSon.reset();
        this.submitted = false;
        this.actualInsumoId = null;
        this.getInsumoById(this.insumo.id);
      });
  }

  validadeDatesForUtilDays() {
    let returnDate = {
      deadline: "",
      utilDays: "",
    };

    const date = {
      deadline: moment(this.formDeadLine.get("deadline").value),
      utilDay: this.formDeadLine.get("utilDay")?.value,
      lastDayUtil: this.formDeadLine.get("lastDayUtil")?.value,
      limitDate: this.formDeadLine.get("limitDate")?.value,
    };

    if (date.utilDay && date.lastDayUtil) {
      returnDate.utilDays = "-1";
      returnDate.deadline = this.getLastUtilDay(date.deadline.toDate());
    } else if (date.utilDay && !date.lastDayUtil) {
      returnDate.utilDays = date.limitDate;
      returnDate.deadline = this.getDateWithAddedDays(
        date.limitDate,
        date.deadline.toDate()
      );
    } else {
      returnDate.utilDays = "d" + date.deadline.date();
      returnDate.deadline = date.deadline.toISOString();
    }

    return returnDate;
  }

  validadeDatesForUtilDaysForSon() {
    let returnDate = {
      deadline: "",
      utilDays: "",
    };

    const date = {
      deadline: moment(this.formDeadLineSon.get("deadline").value),
      utilDay: this.formDeadLineSon.get("utilDay")?.value,
      lastDayUtil: this.formDeadLineSon.get("lastDayUtil")?.value,
      limitDate: this.formDeadLineSon.get("limitDate")?.value,
    };

    if (date.utilDay && date.lastDayUtil) {
      returnDate.utilDays = "-1";
      returnDate.deadline = this.getLastUtilDay(date.deadline.toDate());
    } else if (date.utilDay && !date.lastDayUtil) {
      returnDate.utilDays = date.limitDate;
      returnDate.deadline = this.getDateWithAddedDays(
        date.limitDate,
        date.deadline.toDate()
      );
    } else {
      returnDate.utilDays = "d" + date.deadline.date();
      returnDate.deadline = date.deadline.toISOString();
    }

    return returnDate;
  }

  onSubmitConfig(): void {
    this.submitted = true;
    const tempAlterarInsumo: any = {
      insumoId: this.actualInsumoId,
      userId: this.user.id,
      name: this.insumoUpdate.get("name").value,
      segment: this.insumoUpdate.get("segment").value,
      publico: this.insumoUpdate.get("publico").value,
      competence: this.insumoUpdate.get("competence").value,
      area: this.insumoUpdate.get("area").value,
      insumoType: this.insumoUpdate.get("insumoType").value,
      observations: this.insumoUpdate.get("observations").value,
      acceptedDocs: this.acceptDocs(),
      insumoAmount: this.insumoUpdate.get("insumoAmount").value,
    };

    this.insumosService.updateInsumo(tempAlterarInsumo).subscribe(() => {
      this.submitted = false;
      this.actualInsumoId = null;
      this.insumoUpdate.reset();
      this.closeModalUpdateInsumo.nativeElement.click();
      this.getInsumoById(this.insumo.id);
    });
  }

  acceptDocs() {
    let docTypeString = "";

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck5").value) {
      return "todos";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck1").value) {
      docTypeString += ".xls,";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck2").value) {
      docTypeString += ".xlsx,";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck3").value) {
      docTypeString += ".csv,";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck4").value) {
      docTypeString += ".pdf";
    }

    return docTypeString;
  }

  getInsumoFields() {
    forkJoin([
      this.getInsumosFieldsArea(),
      this.getInsumosFieldsSegmento(),
      this.getInsumosFieldsCadastroPublico(),
      this.getInsumosFieldsTipoDeInsumo(),
    ]).subscribe((resultados) => {
      this.areaOptions = resultados[0];
      this.segmentoOptions = resultados[1];
      this.publicoOptions = resultados[2];
      this.tipoDeInsumoOptions = resultados[3];
    });
  }

  getInsumosFieldsArea() {
    return this.insumoFieldService.getByType("AREA");
  }

  getInsumosFieldsCadastroPublico() {
    return this.insumoFieldService.getByType("CADASTRO_PUBLICO");
  }

  getInsumosFieldsSegmento() {
    return this.insumoFieldService.getByType("SEGMENTO");
  }

  getInsumosFieldsTipoDeInsumo() {
    return this.insumoFieldService.getByType("TIPO_DE_INSUMO");
  }

  getLastUtilDay(date: Date) {
    let lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    let validate = true;

    while (validate) {
      if (lastDay.date() === 0 || lastDay.date() === 6) {
        lastDay.add(1, "days");
      } else {
        validate = false;
      }
    }

    return lastDay.toDate().toISOString();
  }

  getDateWithAddedDays(days: number, date: Date) {
    let index = days;
    let lastDay = moment(new Date(date.getFullYear(), date.getMonth(), 0));

    while (index > 0) {
      lastDay.add(1, "days");

      let isHoliday = false;

      this.holydays.forEach((element) => {
        const elementDay = Number(element.split("-")[2]);
        const elementMonth = Number(element.split("-")[1]);
        if (elementDay == lastDay.date() && elementMonth == lastDay.month()) {
          isHoliday = true;
        }
      });

      if ((lastDay.day() !== 0 && lastDay.day() !== 6) || isHoliday) {
        index--;
      }
    }

    return lastDay.toDate().toISOString();
  }

  addValidators() {
    const insumoValidatorsDto = {
      insumoId: this.insumo.id,
      validators: this.validators,
      userId: this.user.id,
    };
    this.insumosService
      .updateValidators(insumoValidatorsDto)
      .subscribe(() => this.getInsumoById(this.insumo.id));
  }

  removeValidators(userEmail: string) {
    const insumoValidatorsDto = {
      insumoId: this.insumo.id,
      validators: [userEmail],
    };
    this.insumosService
      .removeValidators(insumoValidatorsDto)
      .subscribe(() => this.getInsumoById(this.insumo.id));
  }

  setEmailResponsibleUpload(user) {
    this.formUpdate.get("responsibleUpload").setValue(user.email);
  }

  setEmailAnalysisUpload(user) {
    this.formUpdate.get("analysisUpload").setValue(user.email);
  }

  resetField(form) {
    if (form === 1) {
      this.formUpdate.get("responsibleUpload").reset();
      this.searchUserCtrl1.reset();
    } else if (form === 2) {
      this.formUpdate.get("analysisUpload").reset();
      this.searchUserCtrl2.reset();
    } else if (form === 3) {
      this.formUpdate.get("analysisUpload").reset();
      this.searchUserCtrl2.reset();
      this.formUpdate.get("responsibleUpload").reset();
      this.searchUserCtrl1.reset();
    }
  }

  updatedInsumoResponsavel(insumoResponsavelDto) {
    this.insumosService
      .updateResponsavel(insumoResponsavelDto)
      .subscribe(() => {
        this.closeModalAlterarResponsavel.nativeElement.click();
        this.formUpdate.reset();
        this.resetField(3);
        this.actualInsumoId = null;
        this.getInsumoById(this.insumo.id);
      });
  }

  //uploads

  somArray = (list: any[]) => {
    let total = 0;
    list.map((item) => (total += item.progress));

    return total / list.length;
  };

  removeAnexoFormPreUpload(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  removeFile(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadFile(file) {
    const fileName = file.data.name;
    if (this.insumo.acceptedDocs) {
      if (this.insumo.acceptedDocs !== "todos") {
        const fileNameParts = fileName.split(".");
        const extension = `.${fileNameParts[fileNameParts.length - 1]}`;
        const acceptedDocs = this.insumo.acceptedDocs.split(",");
        if (!acceptedDocs.includes(extension)) {
          this.messageErrorUpload = `"${fileName}" extensão de arquivo incorreta. Verifique os tipos de arquivos permitidos no insumo cadastrado.`;
          this.uploadLoading = false;
          return;
        }
      }
    }
    if (file.data.size > 300000000) {
      this.messageErrorUpload = `O tamanho do arquivo ${fileName} é maior que o limite de 300MB.`;
      this.uploadLoading = false;
      return;
    }
    this.messageErrorUpload = "";
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    const fileBytes = file.data.size;

    this.isLoadingAnexo = true;
    if (fileBytes > 10000000) {
      this.uploadService.validationAws().subscribe((keys) => {
        const splitFileName = String(file.data.name).split(".");
        const mimeType = splitFileName[splitFileName.length - 1];
        const fileKey = uuid.v4();
        const fileName = fileKey + "." + mimeType;

        this.uploadService
          .uploadFileInS3(file.data, fileKey, mimeType, keys)
          .on("httpUploadProgress", function (progress) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            file.progress = progressPercentage;
          })
          .promise()
          .then(() => {
            this.uploadService
              .uploadInsumoAnexoS3(this.user.id, fileKey, fileName)
              .subscribe((body) => {
                this.attachments.push(body);
                this.isLoadingAnexo = false;
                this.uploadLoading = false;
              });
          });
      });
    } else {
      this.uploadSubscription = this.uploadService
        .uploadAnexoInsumo(formData, this.user.id)
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round((event.loaded * 100) / event.total);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError(() => {
            file.inProgress = false;
            this.uploadLoading = false;
            this.isLoadingAnexo = false;
            return of(`${file.data.name} upload failed.`);
          })
        )
        .subscribe((event: any) => {
          if (typeof event === "object") {
            this.attachments.push(event.body);
            this.isLoadingAnexo = false;
            this.uploadLoading = false;
          }
        });
    }
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  cancelarRequisicao(): void {
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
      this.isLoadingAnexo = false;
    }
  }

  onUploadButtonClick() {
    this.uploadLoading = true;
    this.files = [];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    // fileUpload.click();
  }

  //uploads
  downloadFileNotification(fileId) {
    this.downloadFile(fileId);

    this.insumosService
      .downloadNotification(this.user.id, this.insumo.id)
      .subscribe(() => {
        console.log("Send notification");
      });
  }

  downloadFile(fileId: any) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${fileId}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  downloadFileInsumoContent(data: any) {
    const insumoVersion: number = Number(data.version.replace("v", ""));

    const file = data.insumoAnexos.find(
      (insumo) => insumo.version === insumoVersion
    );

    this.insumosService
      .downloadNotification(this.user.id, data.id)
      .subscribe(() => console.log("Send notification"));
    this.downloadFile(file.id);
  }

  changeVersion() {
    if (this.formChangeVersion.invalid) {
      return;
    }

    const insumoVersionDto = {
      insumosId: this.actualInsumoId,
      userId: this.user.id,
      version: this.formChangeVersion.get("version").value,
      justificativa: this.formChangeVersion.get("justificativa").value,
    };

    this.insumosService.updateVersion(insumoVersionDto).subscribe(() => {
      this.closeModalVersion.nativeElement.click();
      this.formChangeVersion.reset();
      this.actualInsumoId = null;
      this.getInsumoById(this.insumo.id);
    });
  }

  getInsumoWithVersion(insumoAnexos: any[], version: string) {
    return insumoAnexos.find((item) => item.version == version);
  }

  getHolidays() {
    this.http
      .get<any[]>(
        `https://date.nager.at/api/v3/PublicHolidays/${new Date().getFullYear()}/BR`
      )
      .subscribe((data) => {
        data.forEach((element) => {
          this.holydays.push(element.date);
        });
      });
  }

  columnsToDisplay = [
    "select",
    "name",
    "publico",
    "segment",
    "competence",
    "deadline",
    "version",
    "dataEntrega",
    "responsavel",
    "actions",
  ];

  displayedColumnsInterno: string[] = [
    "idArquivo",
    "nomeArquivo",
    "Justificativa",
    "dataEHoraEnvio",
    "versao",
    "acoes",
  ];

  displayedColumnsValidators: string[] = [
    "userName",
    "email",
    "userType",
    "acoes",
  ];
}

interface EventEmitterValue {
  competence: string;
}
