import * as uuid from "uuid";
import { environment } from "src/environments/environment";
import { UploadService } from "src/app/_services/upload.service";
import { TicketService } from "src/app/_services/ticket.service";
import { FormService } from "src/app/_services/form.service";
import { AlertService, AccountService } from "src/app/_services";
import { User } from "src/app/_models";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { Router, ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
  Validators,
  FormGroup,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import {
  ViewChild,
  TemplateRef,
  OnInit,
  ElementRef,
  Component,
} from "@angular/core";
import { HttpEventType } from "@angular/common/http";

import * as $ from "../../../assets/jquery-3.5.1.min";

const SOLICITATION_ID = 2;
@Component({
  selector: "app-prorrogacao-regra",
  templateUrl: "./prorrogacao-regra.component.html",
  styleUrls: ["./prorrogacao-regra.component.css"],
})
export class ProrrogacaoRegraComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  user: User;
  formsolicitation: any;
  confirmationValue: any;
  fields: any[] = [];
  confirmedDialogRef: MatDialogRef<any, any>;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files = [];
  attachments: any[] = [];
  @ViewChild("closeModalConfirm") closeModalConfirm: ElementRef;
  @ViewChild("confirmed") confirmed: TemplateRef<any>;

  ticketId: any;
  @ViewChild("openModal") openModal: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private accountService: AccountService,
    private ticketService: TicketService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private uploadService: UploadService,
    private matDialog: MatDialog
  ) {
    this.user = this.accountService.userValue.user;
    this.userToken = this.accountService.userValue.token;
  }

  ngOnInit(): void {
    this.getFormByid();

    this.form = this.formBuilder.group({
      nameDemand: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
      lastId: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
      detailsSolicitation: ["", [Validators.required, Validators.minLength(3)]],
      check1: [""],
      check2: [""],
      check3: [""],
      confirmation: ["", [Validators.required]],
      effectiveDate: ["", [Validators.required]],
    });

    // upload
    $(function () {
      $(".file-input").on("change", function (e) {
        var file = this.files[0];
        $("#file-list-" + $(this).data("id")).append(
          "<span>" + file.name + "</span>"
        );
      });

      $(".file-input-button").on("click", function (e) {
        $("#file-input-" + $(this).data("id")).trigger("click");
      });
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid || !this.confirmationValue) {
      return;
    }

    let ticketDto: any = {};

    let ticketItems: any[] = [];

    this.fields.forEach((field) => {
      if (field.id === 4) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("nameDemand").value;
        ticketItems.push(ticketItem);
      } else if (field.id === 2) {
        if (this.form.get("check1").value) {
          let ticketItem: any = {};
          ticketItem.field = field;
          ticketItem.value = "B2B";
          ticketItems.push(ticketItem);
        }

        if (this.form.get("check2").value) {
          let ticketItem: any = {};
          ticketItem.field = field;
          ticketItem.value = "B2C";
          ticketItems.push(ticketItem);
        }

        if (this.form.get("check3").value) {
          let ticketItem: any = {};
          ticketItem.field = field;
          ticketItem.value = "PRICE";
          ticketItems.push(ticketItem);
        }
      } else if (field.id === 5) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("lastId").value;
        ticketItems.push(ticketItem);
      } else if (field.id === 81) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("effectiveDate").value;
        ticketItems.push(ticketItem);
      } else if (field.id === 3) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("detailsSolicitation").value;
        ticketItems.push(ticketItem);
      }
    });

    ticketDto.ticketItems = ticketItems;
    ticketDto.user = this.user;
    ticketDto.form = this.formsolicitation;

    let ticketPersitedDto: any = {};
    ticketPersitedDto.ticket = ticketDto;
    ticketPersitedDto.anexoTickets = this.attachments;

    this.ticketService.saveOrUpdate(ticketPersitedDto).subscribe((response) => {
      this.ticketId = response;
      this.openModal.nativeElement.click();
      this.alertService.success("Ticket criado com sucesso!", {
        keepAfterRouteChange: true,
        autoClose: true,
      });
    });
  }

  closeConfirmedModal() {
    if (this.confirmedDialogRef) {
      this.confirmedDialogRef.close();
      this.confirmedDialogRef = null;
    }
  }

  navigateToTickets() {
    this.router.navigate(["/meus-tickets"], { relativeTo: this.route });
    this.closeConfirmedModal();
  }

  navigateToMain() {
    this.router.navigate(["/"], {
      relativeTo: this.route,
    });
    this.closeConfirmedModal();
  }

  setFalse() {
    this.form.setValue({
      ...this.form.value,
      confirmation: false,
    });
  }

  check() {
    this.confirmationValue = this.form.get("confirmation").value;
    return this.confirmationValue;
  }

  getFormByid() {
    this.formService.getFormById(SOLICITATION_ID).subscribe((response) => {
      this.formsolicitation = response;
      this.fields = this.formsolicitation["fields"];
      this.dataSource = new MatTableDataSource<PeriodicElement>(
        response["formAnexos"]
      );
    });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  //upload

  removeFile(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    const fileBytes = file.data.size;

    if (fileBytes > 100000000) {
      this.uploadService.validationAws().subscribe((keys) => {
        const splitFileName = String(file.data.name).split(".");
        const mimeType = splitFileName[splitFileName.length - 1];
        const fileKey = uuid.v4();
        const fileName = fileKey + "." + mimeType;

        this.uploadService
          .uploadFileInS3(file.data, fileKey, mimeType, keys)
          .on("httpUploadProgress", function (progress) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            file.progress = progressPercentage;
          })
          .promise()
          .then(() => {
            this.uploadService
              .uploadTicketAnexoS3(this.user.id, fileKey, fileName)
              .subscribe((body) => {
                this.attachments.push(body);
              });
          });
      });
    } else {
      this.uploadService
        .uploadTicketAnexo(formData, this.user.id)
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round((event.loaded * 100) / event.total);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError(() => {
            file.inProgress = false;
            return of(`${file.data.name} upload failed.`);
          })
        )
        .subscribe((event: any) => {
          if (typeof event === "object") {
            this.attachments.push(event.body);
          }
        });
    }
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onUploadButtonClick() {
    this.files = [];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    // fileUpload.click();
  }

  //FormAnexos upload
  userToken: string;
  @ViewChild("anexoUpload", { static: false }) anexoUpload: ElementRef;
  arquivos = [];
  anexos: any[] = [];
  anexoDownload: string = environment.apiUrl + "/download/anexo-form/";
  displayedColumns: string[] = ["fileName", "inclusionDate", "download"];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild("closeConfirmDoc") closeConfirmDoc: ElementRef;
  actualInsumoLength: number = 0;

  onSubmitAnexoForm(): void {
    console.log(this.anexos);
    let updateAnexosFormDto: any = {};

    if (this.actualInsumoLength > 0) {
      updateAnexosFormDto = {
        anexos: this.anexos,
        formId: SOLICITATION_ID,
      };
      this.formService
        .updateAnexos(updateAnexosFormDto)
        .subscribe(() => this.getFormByid());
    } else {
      updateAnexosFormDto = {
        anexos: this.anexos,
        formId: SOLICITATION_ID,
      };

      this.formService
        .updateAnexos(updateAnexosFormDto)
        .subscribe(() => this.getFormByid());
    }
    this.anexos = [];
    this.arquivos = [];
    this.closeConfirmDoc.nativeElement.click();
  }

  downloadAnexoForm(anexoId) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${anexoId}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  removeAnexoForm(anexoId) {
    let ticketRemoveAnexoDto = {
      formId: SOLICITATION_ID,
      anexoId: anexoId,
      userId: this.user.id,
    };

    this.formService.removeAnexoForm(ticketRemoveAnexoDto).subscribe((data) => {
      this.getFormByid();
    });
  }

  removeAllAnexosForm() {
    this.formService.removeAllAnexos(SOLICITATION_ID).subscribe((data) => {
      this.getFormByid();
    });
  }

  removeAnexoFormPreUpload(file: any) {
    this.anexos = this.anexos.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.arquivos = this.arquivos.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadAnexoForm(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    console.log(formData);
    this.uploadService
      .uploadAnexoForm(formData, this.user.id)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError(() => {
          file.inProgress = false;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === "object") {
          this.anexos.push(event.body);
        }
      });
  }

  private uploadAnexosForm() {
    this.anexoUpload.nativeElement.value = "";
    this.arquivos.forEach((file) => {
      this.uploadAnexoForm(file);
    });
  }

  onUploadAnexoButtonClick() {
    this.arquivos = [];
    const anexoUpload = this.anexoUpload.nativeElement;
    anexoUpload.onchange = () => {
      for (let index = 0; index < anexoUpload.files.length; index++) {
        const file = anexoUpload.files[index];
        this.arquivos.push({ data: file, inProgress: false, progress: 0 });
      }
      console.log(anexoUpload);
      this.uploadAnexosForm();
    };
  }

  formPermissions(): boolean {
    if (this.user.profile !== "ROLE_ADMIN") {
      return false;
    } else {
      return true;
    }
  }

  //uploads
  somArray = (list: any[]) => {
    let total = 0;
    list.map((item) => (total += item.progress));

    return total / list.length;
  };
}

// list documents
export interface PeriodicElement {
  fileName: string;
  inclusionDate: string;
  download: string;
}

const formatter = Intl.DateTimeFormat("pt-BR", {
  day: "numeric",
  month: "numeric",
  year: "numeric",
});

const ELEMENT_DATA: PeriodicElement[] = [
  {
    fileName: "Formulario - Fast Track.xlsx",
    inclusionDate: "12/11/2021",
    download: "download",
  },
];
