import * as uuid from "uuid";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { Router, ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import {
  ViewChild,
  TemplateRef,
  OnInit,
  ElementRef,
  Component,
} from "@angular/core";
import { HttpEventType } from "@angular/common/http";

import { UploadService } from "../_services/upload.service";
import { TicketService } from "../_services/ticket.service";
import { FormService } from "../_services/form.service";
import { AlertService, AccountService } from "../_services";
import { User } from "../_models";

const SOLICITATION_ID = 10;

@Component({
  selector: "app-consulta-juridica",
  templateUrl: "./consulta-juridica.component.html",
  styleUrls: ["./consulta-juridica.component.css"],
})
export class ConsultaJuridicaComponent implements OnInit {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  form: FormGroup;
  submitted = false;
  user: User;
  formsolicitation: any;
  fields: any[] = [];
  confirmedDialogRef: MatDialogRef<any, any>;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files = [];
  attachments: any[] = [];
  @ViewChild("closeModalConfirm") closeModalConfirm: ElementRef;
  @ViewChild("confirmed") confirmed: TemplateRef<any>;
  ticketId: any;
  @ViewChild("openModal") openModal: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private accountService: AccountService,
    private ticketService: TicketService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private uploadService: UploadService,
    private matDialog: MatDialog
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.getFormByid();

    this.range = this.formBuilder.group({
      start: ["", [Validators.required]],
      end: ["", [Validators.required]],
    });

    this.form = this.formBuilder.group({
      cnpj: ["", [Validators.required]],
      typeContract: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],

      sla: ["", [Validators.required]],
      detailsSolicitation: ["", [Validators.required, Validators.minLength(3)]],

      // email: ['', [Validators.required, Validators.email]],
      // password: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.minLength(6),
      //     Validators.maxLength(40)
      //   ]
      // ],
      // confirmPassword: ['', Validators.required],
      // acceptTerms: [false, Validators.requiredTrue]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    let ticketDto: any = {};
    let ticketItems: any[] = [];

    this.fields.forEach((field) => {
      if (field.id === 22) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("cnpj").value;
        ticketItems.push(ticketItem);
      } else if (field.id === 23) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value =
          this.range.get("start").value + " - " + this.range.get("end").value;
        ticketItems.push(ticketItem);
      } else if (field.id === 24) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("typeContract").value;
        ticketItems.push(ticketItem);
      } else if (field.id === 71) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("sla").value;
        ticketItems.push(ticketItem);
      } else if (field.id === 3) {
        let ticketItem: any = {};
        ticketItem.field = field;
        ticketItem.value = this.form.get("detailsSolicitation").value;
        ticketItems.push(ticketItem);
      }
    });

    ticketDto.ticketItems = ticketItems;
    ticketDto.user = this.user;
    ticketDto.form = this.formsolicitation;

    let ticketPersitedDto: any = {};
    ticketPersitedDto.ticket = ticketDto;
    ticketPersitedDto.anexoTickets = this.attachments;

    this.ticketService.saveOrUpdate(ticketPersitedDto).subscribe((response) => {
      this.ticketId = response;
      this.openModal.nativeElement.click();
      this.alertService.success("Ticket criado com sucesso!", {
        keepAfterRouteChange: true,
        autoClose: true,
      });
    });
  }
  closeConfirmedModal() {
    if (this.confirmedDialogRef) {
      this.confirmedDialogRef.close();
      this.confirmedDialogRef = null;
    }
  }
  navigateToTickets() {
    this.router.navigate(["/meus-tickets"], { relativeTo: this.route });
    this.closeConfirmedModal();
  }
  navigateToMain() {
    this.router.navigate(["/"], {
      relativeTo: this.route,
    });
    this.closeConfirmedModal();
  }

  check() {
    console.log(this.form.get("check1").value);
  }

  getFormByid() {
    this.formService.getFormById(SOLICITATION_ID).subscribe((response) => {
      this.formsolicitation = response;
      this.fields = this.formsolicitation["fields"];
    });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  // table
  displayedColumns: string[] = [
    "ordinary",
    "expertise",
    "cnpj",
    "start",
    "end",
    "typeContract",
    "detailsSolicitation",
    "urgente",
    "actions",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  //uploads

  removeFile(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    const fileBytes = file.data.size;

    if (fileBytes > 100000000) {
      this.uploadService.validationAws().subscribe((keys) => {
        const splitFileName = String(file.data.name).split(".");
        const mimeType = splitFileName[splitFileName.length - 1];
        const fileKey = uuid.v4();
        const fileName = fileKey + "." + mimeType;

        this.uploadService
          .uploadFileInS3(file.data, fileKey, mimeType, keys)
          .on("httpUploadProgress", function (progress) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            file.progress = progressPercentage;
          })
          .promise()
          .then(() => {
            this.uploadService
              .uploadTicketAnexoS3(this.user.id, fileKey, fileName)
              .subscribe((body) => {
                this.attachments.push(body);
              });
          });
      });
    } else {
      this.uploadService
        .uploadTicketAnexo(formData, this.user.id)
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round((event.loaded * 100) / event.total);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError(() => {
            file.inProgress = false;
            return of(`${file.data.name} upload failed.`);
          })
        )
        .subscribe((event: any) => {
          if (typeof event === "object") {
            this.attachments.push(event.body);
          }
        });
    }
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onUploadButtonClick() {
    this.files = [];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    // fileUpload.click();
  }

  //uploads
  somArray = (list: any[]) => {
    let total = 0;
    list.map((item) => (total += item.progress));

    return total / list.length;
  };
}

// list products
export interface PeriodicElement {
  ordinary: string;
  expertise: string;
  cnpj: string;
  start: string;
  end: string;
  typeContract: string;
  detailsSolicitation: string;
  urgente: string;
  actions: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    ordinary: "",
    expertise: "",
    cnpj: "",
    start: "",
    end: "",
    typeContract: "",
    detailsSolicitation: "",
    urgente: "",
    actions: "",
  },
];
